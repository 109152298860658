<template>
    <div>

        <div class="gender">

            <div :class="{'active':params.status === 0}" @click="statusChanged('waite')">审核</div>
            <div :class="{'active':params.status === 1}" @click="statusChanged('in-stock')">上架</div>
            <div :class="{'active':params.status === 2}" @click="statusChanged('fail')">下架</div>

        </div>

        <div class="list-filter">

            <div class="sorts">
                <template v-for="(label,slug) in sorts">
                    <button :key="slug"
                            :class="{'selected' : params.order_by === slug }"
                            @click="sortClicked(slug)">{{ label }}
                        <template v-if=" slug === 'price' ">

                            <template v-if="params.order_by === 'price' ">
                                <span v-if="params.order_type === 'asc' " class="iconfont pc-shang"></span>
                                <span v-else class="iconfont pc-xia"></span>
                            </template>
                            <span v-else class="iconfont pc-weixuan"></span>
                        </template>
                    </button>
                </template>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "OutfitsFilter",
        model: {
            event: 'change',
            prop: 'filter'
        },
        props: {
            filter: Object
        },
        data() {
            return {
                params: {
                    status: '',
                    order_type: '',
                    order_by: '',
                },
                sorts: {
                    'normal': '综合',
                    'hot': '热度',
                    'new': '新品',
                    'price': '价格',
                },
            }
        },
        methods: {
            statusChanged(status) {
                this.params.status = status

                this.triggerChange()
            },
            sortClicked(slug) {

                let orderType = 'desc';

                if (slug === 'price' && this.params.order_by === slug) {
                    orderType = this.params.order_type === 'desc' ? 'asc' : 'desc';
                }

                this.params.order_type = orderType;
                this.params.order_by = slug;

                this.triggerChange();
            },
            triggerChange() {
                this.$emit('change', this.params);
            }
        }
    }
</script>

<style lang="less" scoped>
    .gender {
        font-size: 14px;
        line-height: 22px;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        margin-bottom: 24px;

        border-bottom: 1px solid #EEEEEE;


        div {
            margin-right: 64px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            position: relative;
            cursor: pointer;
        }

        .active {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);

            &:after {
                content: ' ';
                height: 2px;
                width: 100%;
                background: rgba(0, 0, 0, 0.8);
                position: absolute;
                left: 0;
                bottom: -10px;
            }
        }
    }

    .search {
        max-width: 670px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;

        input, button {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        input {
            flex: 1;
            padding: 8px 16px;
            outline: none;
            border: 1px solid rgba(51, 51, 51, 0.8);
        }

        button {
            width: 70px;
            padding: 8px;
            border: none;
        }
    }

    .categories {
        margin-top: 56px;
        margin-bottom: 56px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;

        button {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .list-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .sorts {
        button {

            background-color: transparent;
            border: none;
            margin-right: 48px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            cursor: pointer;

            &.selected {
                font-weight: bold;
                color: rgba(0, 0, 0, 0.8);
            }
        }

        .iconfont {
            font-size: 14px;
        }
    }

</style>