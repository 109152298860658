<template>
    <main-page>
        <outfits-filter class="filter" @change="filterChanged"/>

        <div>
            <div v-if="!loading && !outfits.length" class="outfit-list empty">
                暂无搭配数据哦
            </div>
            <div v-else-if="loading" class="outfit-list empty">
                <img src="/assets/images/loading.gif"/>
            </div>
            <div v-else class="outfit-list">

                <template v-for="(item,key) in outfits">

                    <div @click="gotoDetail(item)" class="item cr" :key="key">
                        <div v-if="item.status === 2" class="reject">未通过</div>
                        <div v-if="item.status === 1 && item.can_search === 1" class="tjz"></div>
                        <outfit-item :outfit="item"/>
                    </div>

                </template>

                <div class="item"></div>
                <div class="item"></div>
                <div class="item"></div>
            </div>

            <paginate class="pager" :pageCount="pager.count"
                      :clickHandler="pagerClicked"
                      containerClass="paginate-container"
                      :margin-pages="1"
                      :page-range="5"
                      v-model="pager.current"
            />
        </div>
    </main-page>
</template>

<script>
    import MainPage from "@/layout/MainPage";
    import OutfitsFilter from "@/components/OutfitsFilter";
    import api from "@/repo/api";
    import Paginate from "vuejs-paginate";
    import OutfitItem from "@/components/OutfitItem";

    export default {
        name: "Outfit",
        components: {OutfitItem, OutfitsFilter, MainPage, Paginate},
        data() {
            return {
                outfits: [],
                filter: {},
                loading: false,
                pager: {
                    count: 0,
                    current: 1,
                }
            }
        },
        mounted() {
            this.getOutfits()
        },
        methods: {
            getOutfits() {
                this.loading = true

                api.getMyOutfits(this.filter, (data) => {
                    this.loading = false

                    this.outfits = data.data.data
                    let dta = data.data
                    this.outfits = dta.data
                    this.pager.count = Math.round(dta.total / dta.per_page);
                    this.pager.current = dta.current_page;
                })
            },
            pagerClicked() {
                this.filter.page = this.pager.current
                this.getOutfits()
            },
            filterChanged(filter) {
                this.filter = filter
                this.pager.current = 1
                this.filter.page = 1

                this.getOutfits()
            },
            gotoDetail(item) {
                this.$router.push(`outfits/${item.id}`)
            }
        }
    }
</script>

<style lang="less" scoped>
    .filter {
        margin-bottom: 32px;
    }

    .outfit-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &.empty {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            color: #333333;

            img {
                width: 48px;
                height: 48px;
            }
        }
    }

    .item {
        width: 304px;
        position: relative;

        margin-bottom: 32px;

        /deep/ .outfit-item {

            height: 400px;

            .image-container {
                width: 304px;
                height: 304px;
            }
        }

        &:hover .col-btn {
            opacity: 1;
        }
    }

    .cr {
        cursor: pointer;
    }

    .pager {
        margin-bottom: 100px;
    }

    .reject {
        width: 80px;
        height: 24px;
        background: #FFEECB;
        font-size: 14px;
        font-weight: 400;
        color: #FFAD00;
        line-height: 24px;
        text-align: center;
        position: absolute;
        top: 18px;
        right: -12px;
    }

    .tjz{
        width: 56px;
        height: 56px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('/assets/images/tjz.png');
        background-size: 100%;
    }
</style>